<template lang="pug">
	ion-page
		a-navbar
		ion-content.ion-padding
			ion-icon.a-illustration(:src="require('@/plugins/app/_theme/assets/icon/joininput.svg')")
			a-private-field(from-link :default-name='name' :default-code='code')

</template>

<script>
export default {

	components: {
		'a-navbar': () => import('../../app@components/navbar/a-navbar.vue'),
		'a-private-field': () => import('../../app@components/private-field/a-private-field.vue'),
	},
	data() {
		return {
			code: '',
			name: '',
		}
	},
	async mounted() {
		this.code = this.$route.params.code
		this.name = this.$route.params.name
	}
}
</script>

<style lang="sass" scoped>

</style>
